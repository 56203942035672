<template>
  <div :class="merchHeight + ' ' + merchWidth">
  <div class="shirt__container" id="shirt__container" :style="shirtContainerStyles">
    <v-img
      :src="!loadFromJSON? selectedBackShirt : jsonStyle.selectedShirt"
      :width="$vuetify.breakpoint.mobile ? 415 : imageWidth"
      :height="imageHeight"
    >
      <div class="d-flex justify-center align-center mt-16">
        <canvas  :width="width"   :height="height"   id="canvasback" class="fabricCanvas" :style="[!loadFromJSON?{border: '1px dashed #FFFFFF'}:{}]" style=" margin-top: 100px"/>
      </div>
    </v-img>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { fabric } from "fabric";
// import {fabric} from 'fabric'
export default {
  name: "BackMerch",
  data(){
    return{
      canvasback:'',
      text:''
    }
  },
  props: {
    zoomPercentage: Number,
    width:[Number,String],
    height:[Number,String],
    merchWidth:{
      type:String,
      default:'tw-w-full'
    },
    merchHeight:{
      type:String,
      default:'tw-h-full'
    }, imageWidth:{
      type:[String,Number],
      default:700
    },
    imageHeight:{
      type:[String,Number],
      default:'calc(100vh - 64px)'
    },
    shirtContainerStyles:[String,Object,Number],
    loadFromJSON:[Boolean],
    jsonStyle:{Object,String}

  },

  computed:{
    ...mapState('buildMerch',
      [
        'selectedColor',
        'customText',
        'fontFamily',
        'fontSize',
        'fontLetterSpacing',
        'image',
        'textColor',
        'letterAngle',
        'canvas',
        'selectedText',
        'updatedText'
      ]),
    ...mapGetters('buildMerch', ['selectedShirt','selectedBackShirt']),
  },
  mounted() {
    if(this.loadFromJSON){
      let backStyle = this.jsonStyle.back

      let backCanvas = document.getElementById('canvasback')
      this.canvasback = new fabric.Canvas(backCanvas);

      this.canvasback.loadFromJSON(backStyle, this.canvasback.renderAll.bind(this.canvasback), function(o, object) {
        fabric.log(o, object);
      });
    }
  }
};
</script>

<style scoped lang="scss">

.zoom-container {
  width: 140px;
}

.shirt__container {
  transition: all .5s;
}

.playground__container {
  width: calc(100vw - 300px);
  max-width: 1100px;
}
#canvas2{
  //position: relative;
}

</style>
