<template>
  <div  :class="merchHeight + ' ' + merchWidth">
  <div  class="shirt__container front" id="shirt__container" :style="shirtContainerStyles">
    <v-img
    :src="!loadFromJSON? selectedShirt : jsonStyle.selectedShirt"
    :width="$vuetify.breakpoint.mobile ? imageMobileWidth : imageWidth"
      :height="imageHeight"
    >
      <div class="d-flex justify-center align-center tw-w-full mt-16 pt-16">
        <canvas :width="width"  id="canvas2"  class="fabricCanvas" :height="height" :style="[!loadFromJSON?{border: '1px dashed #FFFFFF'}:{}]" style=" margin-top: 100px"/>
      </div>
    </v-img>
  </div>
    <!--      :src="!loadFromJSON?selectedShirt:jsonStyle.selectedShirt || selectedShirt"-->

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { fabric } from "fabric";
// import {fabric} from 'fabric'
export default {
  name: "FrontMerch",
  data(){
    return{
      canvasFront:'',
      text:'',
      frontJson:{}
    }
  },
  props: {
    zoomPercentage: Number,
    width:[Number,String],
    height:[Number,String],
    merchWidth:{
      type:String,
      default:'tw-w-full'
    },
    merchHeight:{
      type:String,
      default:'tw-h-full'
    },
    imageWidth:{
      type:[String,Number],
      default:700
    },
    imageHeight:{
      type:[String,Number],
      default:'calc(100vh - 64px)'
    },
    shirtContainerStyles:[String,Object,Number],
    loadFromJSON:[Boolean],
    jsonStyle: [Object,String],
    imageMobileWidth:{
      type:[String,Number],
      default:415
    }

  },
  computed:{
    ...mapState('buildMerch',
      [
        'selectedColor',
        'customText',
        'fontFamily',
        'fontSize',
        'fontLetterSpacing',
        'image',
        'textColor',
        'letterAngle',
        'canvas',
        'selectedText',
        'updatedText'
      ]),
    ...mapGetters('buildMerch', ['selectedShirt']),
  },
  created() {

    // this.frontJson = JSON.parse(localStorage.getItem('frontBuildJson'))

  },
  mounted() {
    if(this.loadFromJSON){
      // let style =JSON.parse(this.jsonStyle).front
      // // let style = JSON.parse(localStorage.getItem('frontBuildJson')).styles
      // console.log(JSON.parse(JSON.stringify(style)), style)
      // this.loadCanvasFromJSON(JSON.stringify(style))

      this.loadCanvasFromJSON(this.jsonStyle.front)
    }
    // else if (this.$route.name === 'Cart') {
    //   console.log('dd')
    //   console.log(JSON.parse(this.jsonStyle.front))
    //   this.loadCanvasFromJSON(this.jsonStyle.front)
    // }
  },
  methods: {
    loadCanvasFromJSON (jsonObject) {
      let frontCanvas = document.getElementById('canvas2')
      this.canvasFront = new fabric.Canvas(frontCanvas);

      this.canvasFront.loadFromJSON(jsonObject, this.canvasFront.renderAll.bind(this.canvasFront), function(o, object) {
        fabric.log({o, object});
      });
    }
  }
};
</script>

<style scoped lang="scss">

.zoom-container {
  width: 140px;
}

.shirt__container {
  transition: all .5s;
  min-width: 700px;
}

.playground__container {
  width: calc(100vw - 300px);
  max-width: 1100px;
}
#canvas2{
  //position: relative;
  //margin-top: 40px;
}

</style>
